import ApiInstance from './ApiInstance.js'

export default {
  
  getUniversities() {
    return ApiInstance({ requiresAuth: true }).get('/universities');
  },
  addUniversities(Data) {
    return ApiInstance({ requiresAuth: true }).post('/universities', Data);
  },
  editUniversities(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/universities/${id}`, Data);
  },
  deleteUniversities(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/universities/${id}`);
  }
}
